import { axiosApiInstance } from "@/services/base";

class UserInfoService {
  show() {
    return axiosApiInstance.get("/user/info");
  }

  update(data) {
    return axiosApiInstance.put("/user/info", data);
  }
}

export default new UserInfoService();
