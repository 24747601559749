const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 30;

const prefixKey = "_KAGIMA_";

const getKey = (key) => {
  return `${prefixKey}${key}`.toUpperCase();
};

const setCookie = (name, value, expire = DEFAULT_CACHE_TIME) => {
  document.cookie = `${getKey(name)}=${value}; path=/; Max-Age=${expire}`;
};

const getCookie = (name) => {
  const cookieArr = document.cookie.split("; ");
  for (let i = 0, length = cookieArr.length; i < length; i++) {
    const kv = cookieArr[i].split("=");
    if (kv[0] === getKey(name)) {
      return kv[1];
    }
  }
  return "";
};

const removeCookie = (key) => {
  setCookie(key, 1, -1);
};

const clearCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?==)/g);
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = keys[i] + "=0;expire=" + new Date(0).toUTCString();
    }
  }
};

const storage = {
  set: setCookie,
  get: getCookie,
  remove: removeCookie,
  clear: clearCookie,
};

export default storage;
