import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import store from "@/store";

export const refreshAccessToken = async function () {
  const user = await getCurrentUser();

  // Only authorized user need to refresh AccessToken
  if (user && store.getters.isAllow) {
    const token = await user.getIdToken();
    store.dispatch("refreshAccessToken", token);
    store.dispatch("fetchUser", user);
    return true;
  } else {
    authLogout(() => {
      console.warn("Logout");
    });
  }

  return false;
};

export const authLogin = function (callback) {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  setPersistence(auth, browserLocalPersistence)
    .then(async () => {
      return signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user;
          store.dispatch("fetchUser", user);
          callback();
        })
        .catch((error) => {
          console.error(error);
        });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const authLogout = function (callback) {
  const auth = getAuth();
  signOut(auth).then(() => {
    store.dispatch("signOutUser");
    store.dispatch("unsetUserAllow");
    callback();
  });
};

export const isLogin = async function () {
  const user = await getCurrentUser();
  return user && store.getters.isAllow;
};

export const getCurrentUser = async function () {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};
