import { axiosApiInstance } from "@/services/base";

class TeamService {
  list() {
    return axiosApiInstance.get("/teams");
  }

  update_team(uuid) {
    return axiosApiInstance.put(`/teams`, { uuid: uuid });
  }

  list_members(uuid) {
    return axiosApiInstance.get(`/team/${uuid}/members`);
  }

  invite_member(uuid, email) {
    return axiosApiInstance.post(`/team/${uuid}/members`, { email: email });
  }

  // To send a request body with an Axios DELETE request, you should set the data option.
  remove_member(uuid, email) {
    return axiosApiInstance.delete(`/team/${uuid}/members`, {
      data: { email: email },
    });
  }
}

export default new TeamService();
