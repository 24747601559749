import { createRouter, createWebHistory } from "vue-router";
import { features } from "@/features";
import { isLogin } from "@/firebase/auth";
import store from "@/store";

const routes = [
  {
    path: "/users/login",
    name: "Login",
    component: () => import("@/views/users/Login.vue"),
  },
  {
    path: "/users/refresh",
    name: "Refresh",
    component: () => import("@/views/users/Refresh.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/index",
    name: "PublicHome",
    component: () => import("@/views/PublicHome.vue"),
  },
  {
    path: "/",
    name: "Root",
    redirect: { name: "ProductTrendResearch" },
    component: () => import("@/Root.vue"),
    children: [
      {
        path: "",
        name: "Home",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/Home.vue"),
      },
      // Research
      {
        path: "/product_search",
        name: "ProductSearch",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/research/ProductSearch.vue"),
      },
      {
        path: "/product_idea",
        name: "ProductIdea",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/research/ProductIdea.vue"),
      },
      //
      {
        path: "/product_trends",
        name: "ProductTrends",
        redirect: { name: "ProductTrendResearch" },
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/ProductTrends.vue"),
        children: [
          {
            path: "research",
            name: "ProductTrendResearch",
            component: () => import("@/views/product_trend/Research.vue"),
          },
          {
            path: "multiple_niche_research",
            name: "ProductTrendMultipleNicheResearch",
            component: () =>
              import("@/views/product_trend/MultipleNicheResearch.vue"),
          },
          {
            path: "watch_list",
            name: "ProductTrendWatchList",
            component: () => import("@/views/product_trend/WatchList.vue"),
          },
          {
            path: "exclude_lists",
            name: "ProductTrendExcludeLists",
            component: () => import("@/views/product_trend/ExcludeLists.vue"),
          },
          {
            path: "saved_query",
            name: "ProductTrendSavedQuery",
            component: () => import("@/views/product_trend/SavedQuery.vue"),
          },
        ],
      },
      {
        path: "/niche_trends",
        name: "NicheTrends",
        redirect: { name: "NicheTrendResearch" },
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/NicheTrends.vue"),
        children: [
          {
            path: "research",
            name: "NicheTrendResearch",
            component: () => import("@/views/niche_trend/Research.vue"),
          },
        ],
      },
      {
        path: "/niche_analysis",
        name: "NicheAnalysis",
        redirect: { name: "NicheAnalysisNiches" },
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/NicheAnalysis.vue"),
        children: [
          {
            path: "niches",
            name: "NicheAnalysisNiches",
            component: () => import("@/views/niche_analysis/Niches.vue"),
          },
          {
            path: "settings",
            name: "NicheAnalysisSettings",
            component: () => import("@/views/niche_analysis/Settings.vue"),
          },
        ],
      },
      {
        path: "/niche_analysis_detail/:id",
        name: "NicheAnalysisDetail",
        props: true,
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/NicheAnalysisDetail.vue"),
      },
      {
        path: "/product",
        name: "Product",
        redirect: { name: "ProductProducts" },
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/Product.vue"),
        children: [
          {
            path: "products",
            name: "ProductProducts",
            component: () => import("@/views/product/Products.vue"),
          },
          {
            path: "settings",
            name: "ProductSettings",
            component: () => import("@/views/product/Settings.vue"),
          },
        ],
      },
      {
        path: "/new_product",
        name: "NewProduct",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/product/NewProduct.vue"),
      },
      {
        path: "/edit_product/:id",
        name: "EditProduct",
        props: true,
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/product/EditProduct.vue"),
      },
      {
        path: "listing_builder",
        name: "ListingBuilder",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/ListingBuilder.vue"),
      },
      {
        path: "/cerebro",
        name: "Cerebro",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/Cerebro.vue"),
      },
      {
        path: "/setting",
        name: "Setting",
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/Setting.vue"),
      },
      {
        path: "/oauth2/redirect/lwa/:region",
        name: "Oauth2Lwa",
        props: true,
        meta: { auth: true, feature: features.BASIC },
        component: () => import("@/views/Oauth2Lwa.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404NotFound",
    meta: { auth: false, name: "404NotFound" },
    component: () => import("@/views/Error404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name == "PrivacyPolicy" || to.name == "PublicHome") {
    return next();
  }

  const isAuth = await isLogin();

  if (to.name == "Login" && isAuth) {
    return next({ name: "Home" });
  }

  if (to.meta.auth && !isAuth) {
    console.warn("No permission");
    return next({ name: "Login" });
  }

  // [TODO] remove `to.meta.feature != features.BASIC` until clent has teamFeatures
  if (to.meta.feature && to.meta.feature != features.BASIC) {
    let featureCheck = store.getters.teamFeatures.find(
      (e) => e === to.meta.feature
    );
    if (!featureCheck) {
      return next({ name: "Home" });
    }
  }

  window.scrollTo(0, 0);
  next();
});

export default router;
