import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/styles/layout.scss";

import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "@/firebase/config";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/plugins/fontawesome";

import VueGtag from "vue-gtag";

import ActionCableVue from "actioncable-vue";

const setWebSocketConnectionUrl = () => {
  let location = window.location;
  let wsProtocol = "";
  switch (location.protocol) {
    case "https:":
      wsProtocol = "wss";
      break;
    case "http:":
      wsProtocol = "ws";
      break;
  }
  let wsURL = `${wsProtocol}://${location.host}/api/v1/wskt`;
  return wsURL;
};

const actionCableVueOptions = {
  debug: false,
  connectionUrl: setWebSocketConnectionUrl,
  connectImmediately: true,
};

initializeApp(firebaseConfig);
const app = createApp(App);

app.config.globalProperties.$appState = reactive({ theme: "saga-blue" });

app.use(PrimeVue, { ripple: true, inputStyle: "outlined" });
app.use(ConfirmationService);
app.use(ToastService);

app.use(store);
app.use(router);

app.use(ActionCableVue, actionCableVueOptions);

app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(VueGtag, {
  config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID },
});

app.mount("#app");
