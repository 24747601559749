import axios from "axios";
import { refreshAccessToken } from "@/firebase/auth.js";
import store from "@/store";

export const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = store.getters.accessToken;
    config.baseURL = "/api/v1";
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // unauthorize user should not retry
      const result = await refreshAccessToken();
      if (!result) {
        return Promise.reject(error);
      }

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${store.getters.accessToken}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
