import { createStore } from "vuex";
import storage from "@/utils/storage";
import {
  DISPLAY_NAME,
  DISPLAY_MAIL,
  DISPLAY_PHOTO_URL,
  ACCESS_TOKEN,
  DISPLAY_TEAM_NAME,
  DISPLAY_TEAM_EMAIL,
  DISPLAY_TEAM_UUID,
  DISPLAY_TEAM_FEATURES,
  IS_ALLOW,
} from "@/store/mutation-types";

import TeamService from "@/services/team_service";
import UserInfoService from "@/services/user_info_service";

const FeatureJoinSymbol = ":::";

export default createStore({
  state: {
    user: {
      displayName: storage.get(DISPLAY_NAME),
      email: storage.get(DISPLAY_MAIL),
      photoUrl: storage.get(DISPLAY_PHOTO_URL),
      accessToken: storage.get(ACCESS_TOKEN),
    },
    team: {
      name: storage.get(DISPLAY_TEAM_NAME),
      email: storage.get(DISPLAY_TEAM_EMAIL),
      uuid: storage.get(DISPLAY_TEAM_UUID),
      features: storage.get(DISPLAY_TEAM_FEATURES).split(FeatureJoinSymbol),
    },
    productTrend: {
      savedQuery: null,
    },
    teams: [],
    isAllow: storage.get(IS_ALLOW) || false,
  },
  mutations: {
    setUserInfo(state, data) {
      state.user.displayName = data.name;
      state.user.email = data.email;

      storage.set(DISPLAY_NAME, data.name);
      storage.set(DISPLAY_MAIL, data.email);
    },
    setUserPhoto(state, photoUrl) {
      state.user.photoUrl = photoUrl;

      storage.set(DISPLAY_PHOTO_URL, photoUrl);
    },
    setUserAccessToken(state, token) {
      state.user.accessToken = token;

      storage.set(ACCESS_TOKEN, token);
    },
    setTeamInfo(state, data) {
      state.team.name = data.name;
      state.team.uuid = data.uuid;
      state.team.email = data.owner_email;
      state.team.features = data.features;

      let features = data.features.join(FeatureJoinSymbol);
      storage.set(DISPLAY_TEAM_NAME, data.name);
      storage.set(DISPLAY_TEAM_EMAIL, data.owner_email);
      storage.set(DISPLAY_TEAM_UUID, data.uuid);
      storage.set(DISPLAY_TEAM_FEATURES, features);
    },
    setSavedQuery(state, data) {
      state.productTrend.savedQuery = data;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setTeamInfoUuid(state, uuid) {
      state.team.uuid = uuid;
    },
    setAllow(state, allow) {
      storage.set(IS_ALLOW, allow);
      state.isAllow = allow;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      if (user) {
        commit("setUserPhoto", user.photoURL);
        commit("setUserAccessToken", user.accessToken);
      } else {
        commit("setUserPhoto", "");
        commit("setUserAccessToken", "");
      }
    },
    fetchUserFromBackend({ commit }) {
      UserInfoService.show()
        .then(({ data }) => {
          commit("setUserInfo", data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    signOutUser({ commit }) {
      commit("setUserAccessToken", "");
      commit("setUserInfo", { name: "", email: "" });
      commit("setUserPhoto", "");

      storage.remove(DISPLAY_NAME);
      storage.remove(DISPLAY_MAIL);
      storage.remove(DISPLAY_PHOTO_URL);
      storage.remove(ACCESS_TOKEN);

      storage.remove(DISPLAY_TEAM_NAME);
      storage.remove(DISPLAY_TEAM_EMAIL);
      storage.remove(DISPLAY_TEAM_UUID);
    },
    refreshAccessToken({ commit }, token) {
      commit("setUserAccessToken", token);
    },
    fetchTeam({ commit }) {
      TeamService.list()
        .then(({ data }) => {
          commit("setTeamInfo", data.team);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    fetchTeams({ commit }) {
      TeamService.list()
        .then(({ data }) => {
          commit("setTeams", data.teams);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    fetchTeamAndTeams({ commit }, outlander = null) {
      if (outlander) {
        commit("setTeamInfo", outlander.team);
        commit("setTeams", outlander.teams);
      } else {
        TeamService.list()
          .then(({ data }) => {
            commit("setTeamInfo", data.team);
            commit("setTeams", data.teams);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    fetchInfoUuid({ commit }, uuid) {
      commit("setTeamInfoUuid", uuid);
    },
    setSavedQuery({ commit }, query) {
      commit("setSavedQuery", query);
    },
    setUserAllow({ commit }) {
      commit("setAllow", true);
    },
    unsetUserAllow({ commit }) {
      commit("setAllow", false);
    },
  },
  modules: {},
  getters: {
    accessToken: (state) => {
      return state.user.accessToken;
    },
    userName: (state) => {
      return state.user.displayName;
    },
    userEmail: (state) => {
      return state.user.email;
    },
    photoUrl: (state) => {
      return state.user.photoUrl;
    },
    teamName: (state) => {
      return state.team.name;
    },
    teamEmail: (state) => {
      return state.team.email;
    },
    teamUuid: (state) => {
      return state.team.uuid;
    },
    teamFeatures: (state) => {
      return state.team.features;
    },
    savedQuery(state) {
      return state.productTrend.savedQuery;
    },
    teams: (state) => {
      return state.teams;
    },
    isAllow: (state) => {
      return state.isAllow;
    },
  },
});
