import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCrosshairs,
  faArrowTrendUp,
  faCaretDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faChartPie,
  faFileLines,
  faGear,
  faShoppingBag,
  faBinoculars,
  faPencilAlt,
  faListOl,
} from "@fortawesome/free-solid-svg-icons";

library.add(faCrosshairs);
library.add(faArrowTrendUp);
library.add(faCaretDown);
library.add(faCircleArrowLeft);
library.add(faCircleArrowRight);
library.add(faChartPie);
library.add(faFileLines);
library.add(faGear);
library.add(faShoppingBag);
library.add(faBinoculars);
library.add(faPencilAlt);
library.add(faListOl);
